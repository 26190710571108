import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';

import {useConnectionContext} from 'components/connection-list/connection/connection-context.js';
import {Toggle} from '../../../../../../form/form.jsx';
import {useSettingsContext} from '../../../settings-context.js';

const FirstMessage = () => {
  const {connection} = useConnectionContext();
  const {setIsAlertOpen} = useSettingsContext();

  const [field, , helpers] = useField('write_first');
  const [pipelineIdField] = useField('pipeline_id');

  return (
    <Toggle
      label={t`Create deals or leads for outgoing messages`}
      isChecked={
        connection.is_limited || pipelineIdField.value == 0
          ? false
          : field.value
      }
      isDisabled={connection.is_limited || pipelineIdField.value == 0}
      onClick={() => connection.is_limited && setIsAlertOpen(true)}
      onChange={helpers.setValue}
    />
  );
};

export default FirstMessage;
