import React from 'react';
import {t} from 'ttag';

import Entry from '../entry/entry.jsx';
import {useConnectionListContext} from '../../../../connection-list-context.js';
import {useConnectionContext} from '../../../connection-context.js';
import {useSettingsContext} from '../../settings-context.js';

const Pipedrive = () => {
  const {pipelineList, managerList} = useConnectionListContext();

  const {connection} = useConnectionContext();
  const {connectionConfig, groupList} = useSettingsContext();

  const getStrategy = () => {
    if (connectionConfig.strategy == 'random') return t`Random`;
    else if (connectionConfig.strategy == 'uniform') return t`Uniform`;
  };

  const options = managerList?.filter(manager =>
    connectionConfig?.responsible_ids?.includes(manager.crm_user_id)
  );

  const manager = options?.[0];

  const managers = manager?.fullname ? (
    <span style={{display: 'flex'}}>
      <span className="one-line">{manager.fullname}</span>

      {options && options?.length > 1 && (
        <span>{` +${options.length - 1}`}</span>
      )}
    </span>
  ) : undefined;

  const pipeline = pipelineList.find(
    item => item.id == connectionConfig?.pipeline_id
  );

  const stage = groupList?.find(item => item.id == connectionConfig?.stage_id);

  return (
    <>
      {!connection.is_limited && (
        <>
          <Entry
            label={
              !connectionConfig.pipeline_id || connectionConfig?.pipeline_id < 1
                ? t`Lead` + ' / ' + t`Pipeline`
                : t`Pipeline`
            }
            value={
              connectionConfig?.pipeline_id == -1
                ? t`Lead`
                : pipelineList.length
                  ? pipeline?.name || t`Not selected`
                  : t`Loading...`
            }
            color={
              !pipeline &&
              pipelineList.length &&
              connectionConfig.pipeline_id != -1
                ? 'yellow'
                : undefined
            }
          />

          {connectionConfig.pipeline_id > 0 && (
            <>
              <Entry
                label={t`Deal stage`}
                value={
                  groupList
                    ? stage?.name || t`A selection is required`
                    : t`Loading...`
                }
                color={!stage && groupList ? 'yellow' : undefined}
              />
            </>
          )}

          {connectionConfig.pipeline_id != 0 && (
            <>
              <Entry label={t`Strategy`} value={getStrategy()} />
              <Entry label={t`Managers`} value={managers} />
            </>
          )}

          <Entry
            label={t`Working with groups`}
            value={connectionConfig.is_group ? t`Yes` : t`No`}
          />

          {connectionConfig.pipeline_id != 0 && (
            <>
              <Entry
                label={t`Create deals if there are closed ones`}
                value={connectionConfig.create_new_if_close ? t`Yes` : t`No`}
              />

              <Entry
                label={t`Create deals or leads for outgoing messages`}
                value={connectionConfig.write_first ? t`Yes` : t`No`}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default Pipedrive;
