import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';
import {Checkbox} from '../../../../../form/form.jsx';

const IsManager = () => {
  const [field, , helpers] = useField('is_manager');

  return (
    <Checkbox
      label={t`Display the manager’s name in messages`}
      isChecked={!!field.value}
      onChange={value => helpers.setValue(value ? 1 : 0)}
    />
  );
};

export default IsManager;
